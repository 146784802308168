import React from 'react';
import resumeFile from '../documents/cv_berdychowski.pdf';

const AboutUs = () => {
    return (
        <section
            id='about'
            className='section'>
            <div className='container'>
                {/* Heading */}
                <p className='text-center mb-2 wow fadeInUp'>
                    <span className='bg-primary text-dark px-2'>About Me</span>
                </p>
                <h2 className='text-10 fw-600 text-center mb-5 wow fadeInUp'>Know Me More</h2>
                {/* Heading end*/}
                <div className='row'>
                    <div className='col-lg-0 text-center text-lg-start wow fadeInUp'>
                        <h2 className='text-8 fw-400 mb-3'>
                            Hi, I'm <span className='fw-700 border-bottom border-3 border-primary'>Bartek</span> 👋 👨‍💻
                        </h2>
                        <p className='text-5'>More than a decade of involvement in the web development industry has provided me with in-depth knowledge of how online sites work. I have skills that include HTML, CSS, JavaScript, Git, SASS, Less, Gulp and various CSS frameworks. My experience has allowed me to master semantic markup, advanced coding approaches and aspects of website performance, including Pagespeed and many other work tools.</p>
                        <p className='text-5'>Currently, in addition to web design, my interests extend into the area of SEO, including Google Analytics, Google Search Console. I am ready to use this knowledge to continue developing modern and effective online sites.</p>
                    </div>
                </div>
                <div className='row gy-3 mt-4'>
                    <p className='text-center mt-5 wow fadeInUp'>
                        <a
                            className='btn btn-outline-dark shadow-none rounded-0'
                            href={resumeFile}
                            download>
                            Download CV
                        </a>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
