import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import ProjectDetailsModal from './ProjectDetailsModal';
const Portfolio = () => {
    // init one ref to store the future isotope object
    const isotope = useRef();
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = useState('*');
    const [imagesLoaded, setimagesLoaded] = useState(0);
    const [selectedProjectDetails, setSelectedProjectDetails] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const htmlElement = document.getElementsByTagName('html')[0];
    const isRtl = htmlElement.getAttribute('dir') === 'rtl';

    const filters = {
        DETAILED: 'Websites',
        ENEWSLETTERS: 'E-newsletters',
        YOUTUBE: 'Videos',
        MOCKUPS: 'Wireframes',
        LOGOTYPES: 'Logotypes',
        OTHERS: 'Others',
    };

    const types = {
        IMAGE: 'image',
        VIDEO: 'video',
        DOCUMENT: 'document',
    };

    const projectsData = [
        {
            title: 'Cupra',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Cupra',
                graphic: 'No',
                technologies: 'HTML, CSS, JavaScript',
                industry: 'Automotive',
                date: '2023',
                url: {
                    name: 'DEMO',
                    link: 'https://www.berdychowski.com/demo/cupra/',
                },

                sliderImages: ['images/projects/websites/cupra/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/cupra/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Training and Publishing',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript, PHP (Symfony 4 + SonataAdmin + FPDF and FPDI + PayU API)',
                industry: 'Training and Publishing',
                date: '2019',
                url: {
                    name: 'www.szkolenia-konferencje.pl',
                    link: 'https://www.szkolenia-konferencje.pl',
                },

                sliderImages: ['images/projects/websites/explanator/screen-01.png', 'images/projects/websites/explanator/screen-02.png', 'images/projects/websites/explanator/screen-03.png', 'images/projects/websites/explanator/screen-04.png', 'images/projects/websites/explanator/screen-05.png', 'images/projects/websites/explanator/screen-06.png', 'images/projects/websites/explanator/screen-07.png', 'images/projects/websites/explanator/screen-admin-01.png', 'images/projects/websites/explanator/screen-admin-02.png', 'images/projects/websites/explanator/screen-admin-03.png', 'images/projects/websites/explanator/screen-admin-04.png', 'images/projects/websites/explanator/screen-admin-05.png', 'images/projects/websites/explanator/screen-admin-06.png'],
            },

            thumbImage: 'images/projects/websites/explanator/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'E-commerce & digital marketing',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript, PHP (Symfony 2/3/4 + SonataAdmin + FPDF and FPDI + PayU API + Sphinx Search Engine)',
                industry: 'Publishing (Magazine)',
                date: '2018',
                url: {
                    name: 'www.e-commerce-24.pl (offline)',
                    link: 'https://www.e-commerce-24.pl/',
                },

                sliderImages: ['images/projects/websites/ecom/screen-01.png', 'images/projects/websites/ecom/screen-02.png', 'images/projects/websites/ecom/screen-03.png', 'images/projects/websites/ecom/screen-04.png', 'images/projects/websites/ecom/screen-05.png', 'images/projects/websites/ecom/screen-06.png', 'images/projects/websites/ecom/screen-07.png', 'images/projects/websites/ecom/screen-08.png'],
            },

            thumbImage: 'images/projects/websites/ecom/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'System of internal contracts',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript, PHP (Symfony 4 + EasyAdmin + wkHTMLtoPDF)',
                industry: 'Training and Publishing',
                date: '2020',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/contracts/screen-01.png', 'images/projects/websites/contracts/screen-02.png', 'images/projects/websites/contracts/screen-03.png', 'images/projects/websites/contracts/screen-04.png', 'images/projects/websites/contracts/screen-05.png', 'images/projects/websites/contracts/screen-06.png', 'images/projects/websites/contracts/screen-07.png', 'images/projects/websites/contracts/screen-08.png'],
            },

            thumbImage: 'images/projects/websites/contracts/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'New issue of the magazine - Landing Page',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript',
                industry: 'Publishing (Magazine)',
                date: '2017',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/issue/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/issue/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Controlling Magazine',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript',
                industry: 'Publishing (Magazine)',
                date: '2014',
                url: {
                    name: '',
                    link: 'https://www.e-commerce-24.pl/',
                },

                sliderImages: ['images/projects/websites/mc/screen-01.png', 'images/projects/websites/mc/screen-02.png', 'images/projects/websites/mc/screen-03.png', 'images/projects/websites/mc/screen-04.png', 'images/projects/websites/mc/screen-05.png', 'images/projects/websites/mc/screen-06.png', 'images/projects/websites/mc/screen-07.png', 'images/projects/websites/mc/screen-08.png', 'images/projects/websites/mc/screen-09.png'],
            },

            thumbImage: 'images/projects/websites/mc/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'GAGA Atelier',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Fashion',
                date: '2012',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/gaga/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/gaga/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Faculty of Chemistry - University of Wroclaw',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, JavaScript',
                industry: 'Education',
                date: '2011',
                url: {
                    name: '',
                    link: 'https://chem.uni.wroc.pl',
                },

                sliderImages: ['images/projects/websites/chemuniwroc/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/chemuniwroc/thumbnail.png',

            categories: [filters.DETAILED],
        },

        {
            title: 'Study Tour Training Center',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery',
                industry: 'Training and Traveling',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/abszkolenia/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/abszkolenia/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'ZUS - Landing Page',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery',
                industry: 'Insurance and Taxation',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/zus/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/zus/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'CarFox',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'kkVlab',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Fuel',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/carfox/screen-01.png', 'images/projects/websites/carfox/screen-02.png', 'images/projects/websites/carfox/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/carfox/thumbnail.png',

            categories: [filters.DETAILED],
        },

        {
            title: 'InforMed.com.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Medical Portal',
                date: '2011',
                url: {
                    name: 'www.informed.com.pl',
                    link: 'https://www.informed.com.pl/',
                },

                sliderImages: ['images/projects/websites/informed/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/informed/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'KoncepTT.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery',
                industry: 'Legal',
                date: '2010',
                url: {
                    name: 'www.konceptt.pl',
                    link: 'https://www.konceptt.pl/',
                },

                sliderImages: ['images/projects/websites/konceptt/screen-01.png', 'images/projects/websites/konceptt/screen-02.png', 'images/projects/websites/konceptt/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/konceptt/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'ESTE 2010 and Workshop on Luminescence',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, JavaScript',
                industry: 'Conference',
                date: '2010',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/este/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/este/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'OpiszFirme.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript, Flash, ActionScript',
                industry: 'Information Portal',
                date: '2010',
                url: {
                    name: 'www.opiszfirme.pl',
                    link: 'https://www.opiszfirme.pl/',
                },

                sliderImages: ['images/projects/websites/opiszfirme/screen-01.png', 'images/projects/websites/opiszfirme/screen-02.png', 'images/projects/websites/opiszfirme/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/opiszfirme/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'New-House.com.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery',
                industry: 'Construction',
                date: '2010',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/newhouse/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/newhouse/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Photography portfolio',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery',
                industry: 'Photography',
                date: '2010',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/begierski/screen-01.png', 'images/projects/websites/begierski/screen-02.png', 'images/projects/websites/begierski/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/begierski/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Texmed.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Medical Products',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/texmed/screen-01.png', 'images/projects/websites/texmed/screen-02.png', 'images/projects/websites/texmed/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/texmed/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Tax-Pol.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Taxation',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/taxpol/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/taxpol/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Tarmac Aggregates',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'kkVlab',
                graphic: 'No',
                technologies: 'HTML, CSS, JavaScript',
                industry: 'Wholesale Trade in Aggregates',
                date: '2010',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/carfox/screen-01.png', 'images/projects/websites/carfox/screen-02.png', 'images/projects/websites/carfox/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/carfox/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'Kopia24.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Printing Services',
                date: '2009',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/kopia24/screen-01.png', 'images/projects/websites/kopia24/screen-02.png', 'images/projects/websites/kopia24/screen-03.png'],
            },

            thumbImage: 'images/projects/websites/kopia24/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'JanSport - twojeodkrycia.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'kkVlab',
                graphic: 'No',
                technologies: 'HTML, CSS, jQuery, Cufon, JavaScript',
                industry: 'Social Gaming',
                date: '2010',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/twojeodkrycia/screen-01.png', 'images/projects/websites/twojeodkrycia/screen-02.png'],
            },

            thumbImage: 'images/projects/websites/twojeodkrycia/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'ForumMajster.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: 'Yes (Photoshop)',
                technologies: 'HTML, CSS, phpBB',
                industry: 'Social Networking Forum',
                date: '2009',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/websites/forummajster/screen-01.png'],
            },

            thumbImage: 'images/projects/websites/forummajster/thumbnail.png',

            categories: [filters.DETAILED],
        },
        {
            title: 'AB Szkolenia',
            type: types.IMAGE,

            thumbImage: 'images/projects/enewsletters/abszkolenia.png',

            categories: [filters.ENEWSLETTERS],
        },
        // {
        //     title: 'Epicor Business',
        //     type: types.IMAGE,

        //     thumbImage: 'images/projects/enewsletters/epicor-business.png',

        //     categories: [filters.ENEWSLETTERS],
        // },
        // {
        //     title: 'Epicor Stock',
        //     type: types.IMAGE,

        //     thumbImage: 'images/projects/enewsletters/epicor-stock.png',

        //     categories: [filters.ENEWSLETTERS],
        // },
        {
            title: 'Epicor Thanks',
            type: types.IMAGE,

            thumbImage: 'images/projects/enewsletters/epicor-thanx.png',

            categories: [filters.ENEWSLETTERS],
        },
        {
            title: 'Dr Irena Eris SA - Pharmaceris',
            type: types.IMAGE,

            thumbImage: 'images/projects/enewsletters/eris-onetv.png',

            categories: [filters.ENEWSLETTERS],
        },
        {
            title: 'kkVlab Contact',
            type: types.IMAGE,

            thumbImage: 'images/projects/enewsletters/kkv-kontakt.png',

            categories: [filters.ENEWSLETTERS],
        },
        {
            title: 'Sample Streaming Video #2',
            type: types.VIDEO,
            video: {
                vimeo: false,
                id: 'JfgX720MdY4',
            },
            thumbImage: 'images/projects/youtube/streaming-02.png',

            categories: [filters.YOUTUBE],
        },
        {
            title: 'Sample Training Video #1',
            type: types.VIDEO,
            video: {
                vimeo: false,
                id: 'rBP-rYwIw58',
            },
            thumbImage: 'images/projects/youtube/training-01.png',

            categories: [filters.YOUTUBE],
        },
        {
            title: 'Sample Streaming Video #1',
            type: types.VIDEO,
            video: {
                vimeo: false,
                id: 'PwdNIoRTSfE',
            },
            thumbImage: 'images/projects/youtube/streaming-01.png',

            categories: [filters.YOUTUBE],
        },
        {
            title: 'Sample Interview (100seconds) #1',
            type: types.VIDEO,
            video: {
                vimeo: false,
                id: 'nC1OlutVxwY',
            },
            thumbImage: 'images/projects/youtube/interview-01.png',

            categories: [filters.YOUTUBE],
        },
        {
            title: 'E-commerce & digital marketing',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: '',
                technologies: 'Axure RP',
                industry: 'Publishing (Magazine)',
                date: '2017',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/mockups/ecommerce-01.png', 'images/projects/mockups/ecommerce-02.png', 'images/projects/mockups/ecommerce-03.png', 'images/projects/mockups/ecommerce-04.png', 'images/projects/mockups/ecommerce-05.png', 'images/projects/mockups/ecommerce-06.png'],
            },

            thumbImage: 'images/projects/mockups/ecommerce-thumbnail.png',

            categories: [filters.MOCKUPS],
        },
        {
            title: 'Training and Publishing',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'Explanator',
                graphic: '',
                technologies: 'Axure RP',
                industry: 'Training and Publishing',
                date: '2011',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/mockups/explanator-01.png', 'images/projects/mockups/explanator-02.png', 'images/projects/mockups/explanator-03.png', 'images/projects/mockups/explanator-04.png'],
            },

            thumbImage: 'images/projects/mockups/explanator-thumbnail.png',

            categories: [filters.MOCKUPS],
        },
        {
            title: 'Tax-Pol.pl',
            type: types.DOCUMENT,
            document: {
                projectInfo: '',
                client: 'AFI Group',
                graphic: '',
                technologies: 'Axure RP',
                industry: 'Taxation',
                date: '2009',
                url: {
                    name: '',
                    link: '',
                },

                sliderImages: ['images/projects/mockups/taxpol-01.png', 'images/projects/mockups/taxpol-02.png'],
            },

            thumbImage: 'images/projects/mockups/taxpol-thumbnail.png',

            categories: [filters.MOCKUPS],
        },
        {
            title: 'ABszkolenia',
            type: types.IMAGE,

            thumbImage: 'images/projects/logotypes/abszkolenia.png',

            categories: [filters.LOGOTYPES],
        },
        {
            title: 'Kopia24',
            type: types.IMAGE,

            thumbImage: 'images/projects/logotypes/kopia24.png',

            categories: [filters.LOGOTYPES],
        },
        {
            title: 'Terapia',
            type: types.IMAGE,

            thumbImage: 'images/projects/logotypes/terapia.png',

            categories: [filters.LOGOTYPES],
        },
        {
            title: 'Time Finance',
            type: types.IMAGE,

            thumbImage: 'images/projects/logotypes/timefinance.png',

            categories: [filters.LOGOTYPES],
        },
        {
            title: 'Travelina',
            type: types.IMAGE,

            thumbImage: 'images/projects/logotypes/travelina.png',

            categories: [filters.LOGOTYPES],
        },
        {
            title: 'Teaser #1',
            type: types.IMAGE,

            thumbImage: 'images/projects/others/teaser.png',

            categories: [filters.OTHERS],
        },
        // {
        //     title: 'Flyer #2',
        //     type: types.IMAGE,

        //     thumbImage: 'images/projects/others/ortes.png',

        //     categories: [filters.OTHERS],
        // },
        {
            title: 'Flyer #1',
            type: types.IMAGE,

            thumbImage: 'images/projects/others/forummajster.png',

            categories: [filters.OTHERS],
        },
    ];

    // initialize an Isotope object with configs
    useEffect(() => {
        isotope.current = new Isotope('.portfolio-filter', {
            itemSelector: '.filter-item',
            layoutMode: 'masonry',
            originLeft: !isRtl,
        });

        // cleanup
        return () => {
            isotope.current.destroy();
        };
    }, []);

    // handling filter key change
    useEffect(() => {
        if (imagesLoaded) {
            filterKey === '*' ? isotope.current.arrange({ filter: `*` }) : isotope.current.arrange({ filter: `.${filterKey}` });
        }
    }, [filterKey, imagesLoaded]);

    const handleFilterKeyChange = key => () => setFilterKey(key);

    const getKeyByValue = value => {
        return Object.keys(filters).find(key => filters[key] === value);
    };

    const getFilterClasses = categories => {
        if (categories.length > 0) {
            let tempArray = [];
            categories.forEach((category, index) => {
                tempArray.push(getKeyByValue(category));
            });
            return tempArray.join(' ');
        }
    };

    return (
        <>
            <section
                id='portfolio'
                className={'section bg-light'}>
                <div className={'container'}>
                    {/* Heading */}
                    <p className='text-center mb-2 wow fadeInUp'>
                        <span className='bg-primary text-dark px-2'>Portfolio</span>
                    </p>
                    <h2 className='text-10 fw-600 text-center mb-5 wow fadeInUp'>Some of my most recent projects</h2>
                    {/* Heading end*/}
                    {/* Filter Menu */}
                    <ul className={'portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp'}>
                        <li className='nav-item'>
                            <button
                                className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
                                onClick={handleFilterKeyChange('*')}>
                                All
                            </button>
                        </li>
                        {Object.keys(filters).map((oneKey, i) => (
                            <li
                                className='nav-item'
                                key={i}>
                                <button
                                    className={'nav-link ' + (filterKey === oneKey ? 'active' : '')}
                                    onClick={handleFilterKeyChange(oneKey)}>
                                    {filters[oneKey]}
                                </button>
                            </li>
                        ))}
                    </ul>
                    {/* Filter Menu end */}
                    <div className='portfolio wow fadeInUp'>
                        <div className='row portfolio-filter filter-container g-4'>
                            {projectsData.length > 0 &&
                                projectsData.map((project, index) => (
                                    <div
                                        className={'col-sm-6 col-lg-4 filter-item ' + getFilterClasses(project.categories)}
                                        key={index}>
                                        <div className='portfolio-box'>
                                            <div className='portfolio-img'>
                                                <img
                                                    onLoad={() => {
                                                        setimagesLoaded(imagesLoaded + 1);
                                                    }}
                                                    className='img-fluid d-block portfolio-image'
                                                    src={project.thumbImage}
                                                    alt=''
                                                />
                                                <div
                                                    className='portfolio-overlay'
                                                    onClick={() => {
                                                        setSelectedProjectDetails(projectsData[index]);
                                                        setIsOpen(true);
                                                    }}>
                                                    <button className='popup-ajax stretched-link border-0 p-0 '> </button>
                                                    <div className='portfolio-overlay-details'>
                                                        <p className='text-primary text-8'>
                                                            {project.type === types.DOCUMENT && <i className='fas fa-file-alt'></i>}
                                                            {project.type === types.IMAGE && <i className='fas fa-image'></i>}
                                                            {project.type === types.VIDEO && <i className='fas fa-video'></i>}
                                                        </p>
                                                        <h5 className='text-white text-5'>{project?.title}</h5>
                                                        <span className='text-light'>{project?.categories}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
            {/* Modal */}
            {isOpen && (
                <ProjectDetailsModal
                    projectDetails={selectedProjectDetails}
                    setIsOpen={setIsOpen}></ProjectDetailsModal>
            )}
        </>
    );
};

export default Portfolio;
